:root {
  /* Primary colors */
  --primary-pink: #FAC0B1;
  --primary-clay: #A5B1AA;
  --primary-leather: #FF00FF;
  --primary-green: #4B6455;

  /* Other colors */
  --white: #FFFFFF;
  --grey: #BFBFBF;
  --light-grey: #F9F9F9;
  --dark-grey: #666666;
  --grey-disabled: #757575;
  --light-purple: #F4EBEF;
  --light-blue: #ECF5F6;
  --light-orange: #F7BE80;
  
  /* Pantamera colors */
  --transparent: transparent;
  --black: #000000;
  
  /* White */
  --offwhite: #ffffdc;
  --ceramicwhite: #fffffa;
  --beige: #FCF0E0;

  /* Blue */
  --blue: #0094bf;
  --blueFaded: rgba(0, 105, 180, .3);
  --skyblue: #59c2de;
  --lightblue: #eff6ff;
  --lightblue-rgb: 239, 246, 255;
  --darkblue: #113266;

  /* Yellow */
  --lightyellow: #fbec7a;
  --brightsun: #ffce44;
  --honey: #f2a94f;
  --orange: #f7a600;

  /* Colors alerts */
  --green: #6aac6e;
  --checkmarkgreen: #238c34;
  --red: #f22828;
  --darkorange: #ee7202;

  /* Gray */
  --gray: #f2f2f2;
  --lightGrey: #9e9c9c;
}