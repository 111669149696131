:root {
/* Brand colors */
--primary: var(--blue);
--secondary: var(--brightsun);
--white: var(--ceramicwhite);

/* Global */
--backgroundBody: var(--ceramicwhite);
--backgroundOffWhite: var(--offwhite);

/* Text colors */
--colorTextBase: var(--black);
--colorTextSecondary: var(--blue);
--colorTextErrorHeader: var(--blue);
--colorTextDarkBg: var(--ceramicwhite);
--colorHeadingDarkBG: var(--brightsun);
--colorHeadingPrimary: var(--blue);

/* Header */
--backgroundColorHeadingTop: var(--offwhite);
--headerTopLinkHover: var(--darkblue);
--headerTopLinkNavigationInnerHover: var(--skyblue);

/* LoadingIndicator */
--colorTextLoading: var(--blue);
--colorTextLoadingInverted: var(--brightsun);
--colorTextLoadingDarkMode: var(--ceramicwhite);
--backgroundLoadingDot: var(--brightsun);
--backgroundLoadingDotInverted: var(--blue);

/* Common borders */
--borderColorBase: var(--blue);
--borderColorYellow: var(--brightsun);
--borderColorLightBlue: var(--lightblue);
--colorHeadings: var(--blue);

/* links */
--colorLinkBase: var(--blue);
--colorLinkDarkBg: var(--ceramicwhite);
--colorLinkBorder: var(--brightsun);
--colorLinkBorderHover: var(--blue);

/* Menu */
--colorMenuIcon: var(--brightsun);
--borderMenu: var(--offwhite);
--backgroundMenuLevel2: var(--skyblue);
--colorTextMenuLevel1: var(--offwhite);
--colorTextMenuActive: var(--blue);
--colorTextMenuLevel1Expanded: var(--brightsun);

--backgroundMenuExpandLevel1: var(--offwhite);
--backgroundMenuExpandLevel2: var(--blue);
--backgroundMenuExpandLevel1Expanded: var(--blue);
--backgroundMenuExpandLevel2Expanded: var(--blue);
--backgroundMenuExpandActive: var(--brightsun);
--backgroundMenuExpandIcon: var(--blue);
--backgroundExpandPrimary: var(--brightsun);
--backgroundExpandIconPrimary: var(--blue);

--backgroundLogoutButton: var(--darkblue);
--colorTextLogoutButton: var(--ceramicwhite);

--colorMenuTopIcon: var(--blue);

--backgroundMenuLinkHover: var(--darkblue);
--backgroundHeaderBottomMenuPanel: var(--darkblue);
--colorHamburgerMenuButton: var(--blue);

--activeCustomerIdLabelTextColor: var(--black);

/* Mega menu */
--backgroundMegaMenuDropdown: var(--offwhite);
--backgroundBorderActiveMenuItem: var(--brightsun);
--colorMegaMenuLink: var(--skyblue);
--colorMegaMenuLinkArrow: var(--blue);
--colorMegaMenuTeaserHeading: var(--brightsun);
--colorMegaMenuTeaserText: var(--ceramicwhite);
--colorMegaMenuTeaserTextLink: var(--brightsun);

/* Mobile menu */
--backgroundSecondLvl: var(--skyblue);
--backgroundHoverSecondLvl: var(--darkblue);
--colorSecondLvl: var(--offwhite);

--backgroundThirdLvl: var(--offwhite);
--backgroundHoverThirdLvlLi: var(--offwhite);
--backgroundHoverThirdLvl: var(--lightblue);
--colorThirdLvl: var(--blue);

/* Sub menu bar in My pages */
--backgroundSubNavigationBar: var(--ceramicwhite);
--colorSubNavigationBarLink: var(--blue);
--colorSubNavigationBarBorder: var(--lightblue);
--colorSubNavigationBarLinkActiveBorder: var(--brightsun);

/* Common backgrounds */
--backgroundInfo: var(--lightblue);
--backgroundPrimary: var(--blue);
--backgroundSuccess: var(--green);
--backgroundDanger: var(--darkorange);
--backgroundVeryDanger: var(--red);
--backgroundWarning: var(--brightsun);
--backgroundAlert: var(--honey);

/* Checkbox input */
--backgroundCheckedCheckbox: var(--brightsun);
--backgroundCheckbox: var(--offwhite);
--backgroundDisabledCheckbox: var(--lightblue);
--colorCheckmarkIcon: var(--blue);
--borderCheckbox: var(--blue);
--outlineFocusCheckbox: var(--ceramicwhite);

/* Text input */
--backgroundTextInput: var(--offwhite);
--backgroundDisabledTextInput: var(--lightblue);
--outlineFocusTextInput: var(--skyblue);
--outlineErrorTextInput: var(--darkorange);
--borderTextInput: var(--lightblue);
--colorTextInput: var(--black);

/* Toggle input */
--backgroundToggleOption: var(--ceramicwhite);
--backgroundToggleOptionHover: var(--lightblue);
--backgroundToggleOptionSelected: var(--blue);

/* ComponentError */
--borderComponentError: var(--lightblue);

/* Panels */
--borderPanelDefault: var(--lightblue);
--borderPanelWidget: rgba(var(--lightblue-rgb), 0.3);
--colorTextPanelPrimary: var(--ceramicwhite);
--backgroundColorPanelPrimary: var(--skyblue);
--backgroundColorPanelSecondary: var(--lightyellow);
--backgroundColorPanelLightBlue: var(--lightblue);

/* LinkBadge */
--backgroundLinkBadge: var(--lightblue);
--backgroundLinkBadgeYellow: var(--offwhite);
--backgroundLinkBadgeDescriptionIconYellow: var(--brightsun);
--backgroundLinkBadgeDescriptionIcon: var(--blue);
--backgroundLinkBadgeTurquoise: var(--skyblue);
--backgroundLinkBadgeDescriptionIconTurquoise: var(--transparent);
--backgroundLinkBadgeDescriptionIconColorTurquoise: var(--lightyellow);
--borderColorTitleTurquoise: var(--skyblue);

/* Alerts */
--colorTextAlertDefault: var(--ceramicwhite);
--colorTextAlertInfo: var(--blue);

/* Breadcrumb */
--colorTextBreadcrumb: var(--blue);

/* Buttons */
/* Primary */
--backgroundButtonPrimary: var(--brightsun);
--backgroundButtonPrimaryHover: var(--honey);
--colorButtonPrimary: var(--blue);
--colorButtonPrimaryHover: var(--ceramicwhite);
/* Secondary */
--backgroundButtonSecondary: var(--blue);
--backgroundButtonSecondaryHover: var(--honey);
--colorButtonSecondary: var(--brightsun);
--colorButtonSecondaryHover: var(--lightyellow);

/* Pantamera button primary */
--backgroundButtonPantamera: var(--darkblue);
--colorButtonPantamera: var(--ceramicwhite);
--boxShadowColorButtonPantamera: var(--skyblue);
--backgroundcolorButtonPantameraHover: var(--honey);
--colorPantameraButtonHoverBackground: var(--brightsun);
--colorPantameraButtonHover: var(--lightyellow);

/* Pantamera button secondary */
--backgroundButtonPantameraSecondary: var(--honey);
--colorButtonPantameraSecondary: var(--ceramicwhite);
--boxShadowColorButtonPantameraSecondary: var(--lightyellow);
--backgroundcolorButtonPantameraSecondaryHover: var(--darkblue);
--colorPantameraSecondaryButtonHoverBackground: var(--skyblue);
--colorPantameraSecondaryButtonHover: var(--lightblue);

/* Table */
--colorTableHeaderIcon: var(--brightsun);
--backgroundTableHeader: var(--blue);
--backgroundTableHeaderHover: var(--darkblue);
--borderTableBody: var(--lightblue);
--backgroundTableRowOdd: var(--lightblue);
--backgroundTableRowEven: var(--ceramicwhite);

/* Tooltip */
--backgroundTooltip: var(--ceramicwhite);
--borderTooltip: var(--lightblue);

/* Help */
--backgroundHelpContent: var(--ceramicwhite);
--gradientStartHelpButton: var(--blue);
--gradientEndHelpButton: var(--darkblue);

/* Footer */
--backgroundFooter: var(--offwhite);
--backgroundFooterUpper: var(--blue);
--colorFooterUpper: var(--ceramicwhite);
--colorIconFooterUpper: var(--brightsun);

--colorFooterLower: var(--ceramicwhite);
--colorLinkHoverFooterLower: var(--darkblue);
--colorSeperatorFooterLower: var(--brightsun);
--colorLinkFooterLower: var(--blue);
--colorSocialMediaIcon: var(--ceramicwhite);

/* Modal */
--backgroundModal: var(--ceramicwhite);
--colorModalHeader: var(--blue);
--backgroundModalCloseButton: var(--brightsun);
--hoverBackgroundModalCloseButton: var(--honey);

--colorModalCloseButton: var(--blue);

/* Article */
--backgroundCheckboxArea: var(--lightblue);

/* Date picker */
--backgroundDatePickerButtonHover: var(--darkblue);
/* List */
--listBulletColor: var(--blue);

/* StepIndicator */
--backgroundStepIndicator: var(--lightblue);
--backgroundStepIndicatorActive: var(--brightsun);
--backgroundStepIndicatorInRange: var(--blue);
--backgroundStepIndicatorOuterDot: var(--ceramicwhite);

/* Select input */
--borderColorSelect: var(--lightblue);
--colorSelectOption: var(--blue);
--backgroundSelectDropdownIndicator: var(--blue);
--backgroundSelectDropdownIndicatorWidget: var(--brightsun);
--backgroundSelectDropdownIndicatorWidgetIcon: var(--blue);
--colorSelectWidgetText: var(--ceramicwhite);
--backgroundSelectOptionHover: var(--lightblue);
--colorLeftSelectOptionSelected: var(--brightsun);
--backgroundSelectOptionDefault: var(--offwhite);

/* Timeline */
--backgroundTooltipDefault: var(--lightblue);
--backgroundTooltipActive: var(--blue);
--backgroundTooltipInRange: var(--lightblue);
--backgroundTooltipFuture: var(--lightblue);
--colorTooltipDefault: var(--blue);
--colorTooltipActive: var(--ceramicwhite);
--colorTooltipInRange: var(--skyblue);
--colorTooltipFuture: var(--blue);
--backgroundTooltipIcon: var(--ceramicwhite);

/* Play button */
--backgroundColorExternalVideoPlayButton: var(--blue);
--colorExternalVideoPlayButton: var(--ceramicwhite);
--backgroundColorExternalVideoTitle: var(--blue);
--textColorExternalVideoTitle: var(--ceramicwhite);
--focusBorderExternalVideoPlayButton: var(--darkblue);

/* Pending registration */
--pendingRegistrationTitleBgColor: var(--blue);
--pendingRegistrationTitleColor: var(--ceramicwhite);
--pendingRegistrationTextBgColor: var(--lightblue);

/* Sweden */
--swedenTooltipColor: var(--blue);
--swedenPinLabelColorFeatured: var(--blue);
--swedenPinLabelColor: var(--brightsun);

/* Deposit label */
--depositLabelSummaryImageBgColor: var(--lightblue);
--depositLabelOrderSummaryBgColor: var(--lightblue);
--depositCodeColor: var(--black);

/* Balance sheet */
--balanceSheetSeparatorColor: var(--skyblue);
--balanceSheetDataRowBgColor: var(--lightblue);
--balanceSheetDataRowLastColBgColor: var(--lightblue);
--balanceSheetTotalRowBgColor: var(--ceramicwhite);
--balanceSheetTotalBorderColor: var(--skyblue);

/* Schedule */
--scheduleHeadingColor: var(--blue);
--scheduleBarColor: var(--skyblue);
--scheduleDayColor: var(--blue);
--scheduleDayColumnColor: var(--blue);
--scheduleTodayColor: var(--blue);
--scheduleTodayBgColor: var(--brightsun);
--scheduleWeekBgColor: var(--blue);
--backgroundScheduleContainer: var(--offwhite);

/* Pickups */
--nextPickupBgColorLarge: var(--lightblue);
--nextPickupTextColorLarge: var(--blue);
--nextPickupTextColorSmall: var(--ceramicwhite);
--extraPickupSummaryBgColor: var(--lightblue);
--nBinsIconColor: var(--brightsun);
--nBinsColor: var(--blue);
--nBinsColorDashboard: var(--offwhite);
--equipmentNotAvailableBgColor: var(--lightblue);

/* Widgets */
--colorWidgetText: var(--ceramicwhite);
--pastPickupsPanelBgColor: var(--lightblue);

/* PexMap */
--colorPexMapInfoHeadings: var(--blue);
--backgroundColorRoadSmall: var(--ceramicwhite);
--backgroundColorLandscape: var(--gray);
--backgroundColorTransit: var(--gray);
--backgroundColorWater: var(--skyblue);
--backgroundColorMapCluster: var(--blue);
--textColorMapCluster: var(--brightsun);

/* Dashboard */
--dashboardTitleColor: var(--blue);
--dashboardBackgroundColor: var(--offwhite);

/* MonthlyStats */
--monthlyStatsWidgetTextColor: var(--ceramicwhite);
--monthlyStatsPetColor: var(--blue);
--monthlyStatsCanColor: var(--brightsun);
--monthlyStatsWidgetLegendTextColor: var(--ceramicwhite);

/* Search */
--searchResultsBgColor: var(--ceramicwhite);
--searchResultsInputTextColor: var(--blue);
--searchResultHeaderTextColor: var(--ceramicwhite);
--searchBorderColor: var(--skyblue);
--searchRecommendationsHeaderColorLight: var(--ceramicwhite);
--searchResultLinkColor: var(--blue);
--searchBlockBgColor: var(--blue);

--backgroundSelectedPaginationPage: var(--brightsun);
--backgroundPaginationPage: var(--ceramicwhite);
--backgroundHoverPaginationPage: var(--skyblue);
--borderPaginationPage: var(--skyblue);

/* Faq */
--faqToggleButtonTextColor: var(--ceramicwhite);
--faqToggleButtonSelectedTextColor: var(--blue);
--faqToggleButtonBgColor: var(--blue);
--faqToggleButtonSelectedBgColor: var(--ceramicwhite);
--faqFilterBgColor: var(--lightblue);
--faqFilterBorderColor: var(--skyblue);
--faqItemBorderColor: var(--skyblue);

/* BarChart */
--shadowColorBarHover: var(--blueFaded);
--backgroundBarPartPrimary: var(--blue);
--backgroundBarPartSecondary: var(--brightsun);
--backgroundBarPartOther: var(--honey);

/* MonthlyReports */
--monthlyReportsWidgetTextColor: var(--ceramicwhite);
--checkmarkGreen: var(--checkmarkgreen);

/* Go to content link */
--backgroundGoToContent: var(--lightblue);

/* Video controls */
--backgroundVideoControls: var(--brightsun);
--colorVideoControls: var(--blue);

/* Webshop */
--webshopWidgetTextColor: var(--ceramicwhite);

/* Cookie banner */
--backgroundCookieBanner: var(--blue);
--colorCookieBanner: var(--ceramicwhite);

/* Föreningskampen */
--backgroundTopListHeader: var(--blue);
--associationBattleBlue: var(--blue);
--associationBattleWhite: var(--ceramicwhite);
--backgroundTopListItemOwn: var(--brightsun);
--colorTopListItemOwn: var(--blue);
--associationBattleYellow: var(--brightsun);
--colorTopListSeparator: var(--skyblue);

/* BoxBody */
--colorBoxBodyLink: var(--ceramicwhite);

/* BoxHeader */
--colorBoxBodyContainerBorderBottom: var(--skyblue);

/* UnsupportedBrowser */
--backgroundUnsupportedBrowser: var(--beige);

/* QuestionBubble */
--questionBubbleOrange: var(--orange);

/* Icon */
--iconWarningColor: var(--orange);
--iconNotInUseColor: var(--lightGrey);
--iconInUseColor: var(--green);

/* Sales statements */
--backgroundColorSalesStatementsPanel: var(--lightblue);
--colorTextSalesYear: var(--blue);
--colorDarkTableHeaderBackground: var(--skyblue);
--colorBorderSalesStatementsMobileTable: var(--blue);    
    
/* Store flyers */
--backgroundStoreFlyersBlock: var(--blue);
--colorStoreFlyersHeading: var(--lightyellow);    
}
